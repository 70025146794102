<template>
    <div v-observe-visibility="isVisible" class="boards-list-container">
        <div
            v-infinite-scroll="fetchList"
            infinite-scroll-immediate-check="false"
            infinite-scroll-disabled="fetchingList"
            infinite-scroll-distance="400"
            class="boards-list"
        >
            <div
                v-masonry="masonryListName"
                transition-duration=".3s"
                item-selector=".item"
                :gutter="30"
                :fit-width="true"
                :origin-top="false"
                :destroy-delay="2000"
            >
                <template v-for="board in feedList">
                    <board-card
                        :key="board.id"
                        v-masonry-tile
                        class="item"
                        :board="board"
                    />
                </template>
            </div>
        </div>
        <div v-if="!feedList.length && firstAttemptToFetchData" class="not-found-memod-list">
            <div class="icon">
                <img :src="noMemosFoundMessage.icon">
            </div>
            <h5>{{ noMemosFoundMessage.title }}</h5>
            <p>{{ noMemosFoundMessage.text }}</p>
        </div>
        <fullscreen-loader v-if="fetchingList && isLoggedIn" />
    </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import infiniteScroll from "vue-infinite-scroll";
import { mapGetters } from "vuex";

export default {
    name: "BoardsList",
    components: {
        FullscreenLoader: () => import(/* webpackChunkName: "fullscreen-loader"*/ "@c/molecules/fullscreen-loader.vue"),
        BoardCard: () => import(/* webpackChunkName: "board-card" */ "@/components/molecules/board-card/")
    },
    directives: {
        infiniteScroll
    },
    props: {
        useApiV2: {
            type: Boolean,
            default: false
        },
        listEndpoint: {
            type: String,
            required: true
        },
        noMemosFoundMessage: {
            type: Object,
            default() {
                return {
                    icon: require("@assets/img/icons/feed.svg"),
                    title: "Nothing found",
                    text: "Sorry nothing found."
                }
            }
        }
    },
    data() {
        return {
            // This is temporary needed due to an inconsistency in the backend pagination,
            // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
            // backend after there's no memods left in the data array that is being returned by the backend
            noDataLeft: false,
            fetchingList: false,
            feedList: [],
            firstAttemptToFetchData: false,
            feedListPagination: {
                limit: 25,
                page: null,
                total_pages: 1
            }
        };
    },
    computed: {
        ...mapGetters({
            isLoggedIn: "User/isLoggedIn"
        }),
        masonryListName() {
            if (this.$parent.$options.name) {
                return `${this.$parent.$options.name}-list`
            } else {
                return `${this.$route.name}`
            }
        }
    },
    watch: {
        listEndpoint: {
            immediate: true,
            handler() {
                this.resetBoardsList();
                this.fetchList();
            }
        }
    },
    methods: {
        isVisible(isVisible) {
            if (isVisible) {
                this.$redrawVueMasonry(this.masonryListName);
            }
        },
        fetchList() {
            // This is temporary needed due to an inconsistency in the backend pagination,
            // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
            // backend after there's no memods left in the data array that is being returned by the backend
            if (this.noDataLeft) {
                return;
            }

            const limit = this.feedListPagination.limit;
            const nextPage = this.feedListPagination.page ? (this.feedListPagination.page + 1) : 1;

            if (Number(nextPage) <= this.feedListPagination.total_pages) {
                this.fetchingList = true;
                axios.get(`${this.listEndpoint}${this.$route.params.searchString ? "" : "&format=true"}&light=true&limit=${limit}&page=${nextPage}`).then((response) => {
                    // This is temporary needed due to an inconsistency in the backend pagination,
                    // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
                    // backend after there's no memods left in the data array that is being returned by the backend
                    if (response.data.hasOwnProperty("data") && !response.data.data.length) {
                        this.noDataLeft = true;
                        return;
                    }

                    const boards = response.data.data ? response.data.data : response.data.results;
                    const page = response.data.page ? response.data.page : response.data.meta.page.current;
                    const totalPages = response.data.total_pages ? response.data.total_pages : response.data.meta.page.total_pages;

                    this.feedList = boards;
                    this.feedListPagination.page = page;
                    this.feedListPagination.total_pages = totalPages;
                    this.fetchingList = false;

                }).finally(() => {

                    if (!this.firstAttemptToFetchData) {
                        setTimeout(() => {
                            this.$redrawVueMasonry(this.masonryListName);
                        }, 300);
                    } else {
                        this.$redrawVueMasonry(this.masonryListName);
                    }

                    this.firstAttemptToFetchData = true;
                    this.fetchingList = false;
                });
            }
        },
        getTagTitle(tag) {
            return `#${tag.name}`;
        },
        getTagSubtitle(tag) {
            return `${tag.total_memos} ${Number(tag.total_memos) > 1 ? "Memos" : "Memo"}`
        },
        getTagPicture(tag) {
            const { files } = tag;
            if (files.length) {
                return files[0];
            }
            return false;
        },
        resetBoardsList() {
            this.firstAttemptToFetchData = false;
            this.feedList = [];
            this.feedListPagination.page = null;
            this.feedListPagination.total_pages = 1;
        }
    }
}
</script>

<style lang="scss" scoped>
.boards-list-container {
    position: relative;

    .loader {
        position: relative;
        background-color: transparent;
        height: 200px;

        /deep/ img {
            width: 100px !important;
        }
    }

    .boards-list {
        display: flex;
        justify-content: center;
        padding-top: 40px;

        .board-card {
            margin: 0 auto;
            margin-bottom: 30px;
        }
    }

    .not-found-memod-list {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 230px;
        margin: 0 auto;
        margin-top: 80px;
        text-align: center;

        .icon {
            width: 72px;
            height: 72px;
            border: 2px solid white;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;

            img {
                width: 37px;
            }
        }

        h5 {
            font-size: 18px;
            line-height: 18px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 0;
            color: rgba(155, 155, 162, 0.6);
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
        }
    }
}
</style>
