<template>
    <div class="popover-options infinite">
        <template v-for="board in boards">
            <div :key="board.id" class="popover-option" @click="saveMemoToBoard(memod.id, board.id, board.list_name)">
                <span :class="{ 'is_inside' : board.is_inside }">{{ board.list_name }}</span>
                <img v-if="board.is_inside && boardSavingMemo !== Number(board.id)" src="@assets/img/icons/check-green.svg" alt="Check">
                <loader v-if="boardSavingMemo === Number(board.id)" :size="20" />
            </div>
        </template>
        <div v-if="!areAllBoardsLoaded && !noDataLeft" v-observe-visibility="(isVisible) => isLoadingMoreVisible = isVisible" class="popover-option loading-more-boards">
            <span>Loading {{ boards.length ? 'more' : 'your' }} boards...</span>
            <loader :size="20" />
        </div>
    </div>
</template>

<script>
/* eslint-disable no-prototype-builtins */
import { mapState } from "vuex";

export default {
    name: "BoardsList",
    components: {
        loader: () => import(/* webpackChunkName: "loader"*/ "@c/atoms/loader.vue")
    },
    props: {
        memod: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            noDataLeft: false,
            fetchingList: false,
            boards: [],
            boardsPagination: {
                limit: 5,
                page: null,
                total_pages: 1
            },
            isLoadingMoreVisible: false,
            boardSavingMemo: null
        }
    },
    computed: {
        ...mapState({
            userId: state => state.User.data.id
        }),
        areAllBoardsLoaded() {
            return this.boardsPagination.page === this.boardsPagination.total_pages;
        }
    },
    watch: {
        isLoadingMoreVisible() {
            if (this.isLoadingMoreVisible) {
                this.fetchBoards();
            }
        }
    },
    methods: {
        fetchBoards() {
            // This is temporary needed due to an inconsistency in the backend pagination,
            // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
            // backend after there's no memods left in the data array that is being returned by the backend
            if (this.noDataLeft) {
                return;
            }

            const limit = this.boardsPagination.limit;
            const nextPage = this.boardsPagination.page ? (this.boardsPagination.page + 1) : 1;

            if (Number(nextPage) <= this.boardsPagination.total_pages) {
                this.fetchingList = true;
                axios.get(`/users/${this.userId}/lists?memo_id=${this.memod.id}&format=true&limit=${limit}&page=${nextPage}`).then((response) => {
                    // This is temporary needed due to an inconsistency in the backend pagination,
                    // the totalpages property is nos correctly being calculated, so we need a way to stop reaching the
                    // backend after there's no memods left in the data array that is being returned by the backend
                    if (response.data.hasOwnProperty("data") && !response.data.data.length) {
                        this.noDataLeft = true;
                        return;
                    }

                    const boards = response.data.data ? response.data.data : response.data.results;
                    const page = response.data.page ? response.data.page : response.data.meta.page.current;
                    const totalPages = response.data.total_pages ? response.data.total_pages : response.data.meta.page.total_pages;
                    const limit = response.data.limit ? response.data.limit : response.data.meta.page.limit;

                    for (var i = 0; i < boards.length; i++) {
                        this.boards.push({ ...boards[i] });
                    }

                    this.boardsPagination.page = page;
                    this.boardsPagination.total_pages = totalPages;
                    this.boardsPagination.limit = limit;

                }).finally(() => {
                    this.fetchingList = false;
                });
            }
        },
        saveMemoToBoard(memoToSaveId, boardId, boardTitle) {
            this.boardSavingMemo = boardId;
            this.$store.dispatch("Boards/toggleMemoToBoard", { memoId: memoToSaveId, boardId, boardTitle }).then((data) => {
                this.$emit("toggled-save", data.is_saved);
                this.boardSavingMemo = null;
                this.handleSavedMemoInBoard(data);
            });
        },
        handleSavedMemoInBoard(data) {
            const savedBoardIndex = this.boards.findIndex(board => board.id == data.list_id);
            this.boards[savedBoardIndex].is_inside = data.is_inside;
        }
    }
}
</script>
